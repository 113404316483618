import axios from 'axios'

const config = {
  // eslint-disable-next-line no-undef
  baseURL: appConfig?.reports,
}
const httpClient = axios.create(config)

const getPDFReport = (language, token, body) => {
  const params = {
    headers: {
      'x-access-token': token,
    },
    responseType: 'blob',
  }
  return httpClient.post(config.baseURL + 'pdf?lng=' + language, body, params)
}

export { getPDFReport }
