<template>
  <div>
    <Subheader :hasScrolled="hasScrolled" />
    <ReportingMainStyled>
      <header>
        {{ title }}
      </header>
      <main>
        <div class="timeformat">
          <div class="label">
            <strong>
              {{ $t('reporting.report.timeFormat') }}
            </strong>
          </div>
          <Multiselect
            class="select"
            v-model="selectedTimeFormat"
            trackBy="id"
            label="label"
            :options="timeFormatsAvailable"
            :multiple="false"
            :show-labels="false"
            :placeholder="$t('selects.general')"
          />
        </div>
        <div class="year" :class="{ faded: !showYearSelection }">
          <div class="label">
            <strong>
              {{ $t('reporting.stepper.stepOne.types.yearly') }}
            </strong>
          </div>
          <Multiselect
            class="select"
            v-model="selectedYear"
            :options="yearsAvailable"
            :multiple="false"
            :show-labels="false"
            :placeholder="$t('selects.general')"
          />
        </div>
        <div class="month" :class="{ faded: !showMonthSelection }">
          <div class="label">
            <strong>
              {{ $t('reporting.stepper.stepOne.types.monthly') }}
            </strong>
          </div>
          <Multiselect
            class="select"
            v-model="selectedMonth"
            trackBy="id"
            label="label"
            :disabled="!this.selectedYear"
            :options="monthsAvailable"
            :multiple="false"
            :show-labels="false"
            :placeholder="$t('selects.general')"
          />
        </div>
        <div class="week" :class="{ faded: !showWeekSelection }">
          <div class="label">
            <strong>
              {{ $t('reporting.stepper.stepOne.types.weekly') }}
            </strong>
          </div>
          <Multiselect
            class="select"
            v-model="selectedWeek"
            trackBy="id"
            label="label"
            :disabled="!this.selectedYear"
            :options="weeksAvailable"
            :multiple="false"
            :show-labels="false"
            :placeholder="$t('selects.general')"
          />
        </div>
        <div class="day" :class="{ faded: !showDaySelection }">
          <div class="label">
            <strong>
              {{ $t('reporting.stepper.stepOne.types.daily') }}
            </strong>
          </div>
          <Multiselect
            class="select"
            v-model="selectedDay"
            trackBy="id"
            label="label"
            :disabled="!this.selectedMonth"
            :options="daysAvailable"
            :multiple="false"
            :show-labels="false"
            :placeholder="$t('selects.general')"
          />
        </div>
        <div class="selected-timeframe" :class="{ faded: !selectedTimeframe }">
          <div class="label">
            <strong>
              {{ $t('reporting.stepper.stepOne.subtitle') }}
            </strong>
          </div>
          <div class="value">
            {{ selectedTimeframeShown }}
          </div>
        </div>
        <div class="section">
          <div v-for="section in sectionsMapped" :key="section.label" class="export">
            <ToggleButton @click="toggleSection(section.label)">
              <OnOffToggle class="toggle" :value="section.enabled" /> {{ $t('reporting.sections.' + section.label) }}
            </ToggleButton>
            <div class="details">
              <Dimension :key="i" v-for="(dimension, i) in section.dimensions">
                {{ kpiNameFinder(dimension) }}
              </Dimension>
            </div>
          </div>
        </div>
        <div class="download">
          <ButtonRound v-if="canDownloadCSV" :disabled="!canExport" @click="exportCSV"> Download CSV </ButtonRound>
          <PDFReport
            :reportingPDFRead="reportingPDFRead"
            v-if="!isExporting"
            :assetsSelected="assetsSelected"
            :selectedTimeframe="selectedTimeframe"
            :canExport="canExport"
            :sections="sectionsMapped"
            @error="errorMapped($event)"
            @pdfLoading="pdfLoading"
          />
          <div v-else class="loading">{{ $t('reporting.report.processing') }} {{ loadingPercent }} %</div>
        </div>
      </main>
      <div class="errors" v-if="errors.length > 0">
        <div class="error" v-for="(error, i) in errors" :key="i">
          {{ error.toString() }}
        </div>
      </div>
    </ReportingMainStyled>
  </div>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import round from '@/utils/round'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import pLimit from 'p-limit'
import convert from '@common/convert-units'
import Subheader from '@/components/Dashboard/Main/Subheader.vue'
import Multiselect from 'vue-multiselect'
import { DateTime, Interval } from 'luxon'
import { flexCenter, flexColumns, buttonReset } from '@styles/mixins'
import { OnOffToggle } from '@common/components'
import { exportToBlob } from '@/utils/export'
import { ButtonRound as ButtonRoundBase } from '@styles/buttons'
import localesMixin from '@/mixins/locales'
import permissionsMixin from '@/mixins/permissions'
import PDFReport from './PDFReporting'
import { getAssetDimensionNameByLocale } from '@common/utils/src'
import { useAssetStore } from '@/stores/assets'

import PROFILE_QUERY from '#/graphql/profile/show.gql'
import ASSETS_MINIMAL_QUERY from '#/graphql/operations/assets/minimals/assetsMinimal.gql'
import CSV_DATA_QUERY from '#/graphql/assetDimensions/csvData.gql'
import ASSETS_DIMENSIONS_QUERY from '#/graphql/assetDimensions/assetDimensions.gql'

const limit = pLimit(4)

const ReportingMainStyled = styled('div')`
  display: grid;
  height: 100%;
  grid-template-rows: 4rem 1fr;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-items: center;

  header {
    align-self: center;
    font-size: 1.5rem;
  }

  main {
    padding: 1rem;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
      grid-template-columns: repeat(5, 1fr);
    }
    .label {
      margin-bottom: 1rem;
      text-align: center;
    }
    .select {
      width: 12rem;
    }
    .selected-timeframe {
      padding: 1rem;
      height: 6rem;
      @media (min-width: 768px) {
        grid-column: span 5;
      }
      .value {
        text-align: center;
      }
    }
    > div {
      transition: opacity 0.25s;
      &.faded {
        opacity: 0;
      }
    }
    .download {
      ${flexCenter}
      margin: 2rem 0;
      @media (min-width: 768px) {
        grid-column: span 5;
      }
    }
    .details {
      ${flexColumns}
    }
    .section {
      justify-content: space-around;
      display: flex;
      grid-column: span 5;
    }
  }
`

const ToggleButton = styled('button')`
  ${buttonReset}
  ${flexCenter}
  .toggle {
    margin-right: 1rem;
  }
`

const Dimension = styled('div')`
  font-size: 0.7rem;
  padding-left: 3.5rem;
`

const ButtonRound = styled(ButtonRoundBase)`
  background: ${({ theme }) => theme.colors.primary};
  width: 10rem;
  border-radius: 1.5rem;
  margin-right: 1rem;
`

export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  inject: ['uiSettings', 'permissions'],
  mixins: [localesMixin, permissionsMixin],
  props: {
    hasScrolled: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ReportingMainStyled,
    Multiselect,
    ToggleButton,
    OnOffToggle,
    Dimension,
    ButtonRound,
    Subheader,
    PDFReport,
  },
  data() {
    return {
      selectedTimeFormat: null,
      selectedYear: null,
      selectedMonth: null,
      selectedWeek: null,
      selectedDay: null,
      sectionsDisabled: [],
      errors: [],
      isExporting: false,
      queuedQueries: 0,
      finishedQueries: 0,
      assetDimensions: [],
      isPdfLoading: false,
    }
  },
  computed: {
    assetsSelected() {
      return this.assetStore.assetsSelectedInReport()
    },
    canDownloadCSV() {
      return this.reportingCSVRead && !this.isExporting && !this.isPdfLoading
    },
    timeFormatsAvailable() {
      return [
        {
          id: 'month',
          label: this.$t('reporting.stepper.stepOne.types.monthly'),
        },
        {
          id: 'week',
          label: this.$t('reporting.stepper.stepOne.types.weekly'),
        },
        {
          id: 'day',
          label: this.$t('reporting.stepper.stepOne.types.daily'),
        },
      ]
    },
    loadingPercent() {
      return Math.ceil((this.finishedQueries / this.queuedQueries) * 100)
    },
    assetDimensionNames() {
      return this.sections.reduce((acc, section) => {
        return acc.concat(section.dimensions)
      }, [])
    },
    sectionsMapped() {
      return this.sections
        .map(section => {
          const dimensions = section.dimensions.filter(d => this.assetDimensions.find(f => f.name === d))
          return {
            ...section,
            dimensions: dimensions,
          }
        })
        .filter(f => f.dimensions.length > 0)
    },
    sections() {
      return [
        {
          label: 'utilization',
          enabled: !this.sectionsDisabled.includes('utilization'),
          dimensions: ['working_time', 'idle_time', 'operation_time', 'utilization', 'average_loading_duration'],
        },
        {
          label: 'production',
          enabled: !this.sectionsDisabled.includes('production'),
          dimensions: ['tonnage', 'no_passes', 'tonnage_per_hour'],
        },
        {
          label: 'fuel_consumption',
          enabled: !this.sectionsDisabled.includes('fuel_consumption'),
          dimensions: ['distance', 'fuel_consumption'],
        },
        {
          label: 'summary',
          enabled: !this.sectionsDisabled.includes('summary'),
          dimensions: [
            'utilization',
            'idle_time',
            'operation_time',
            'tonnage',
            'fuel_consumption',
            'distance',
            'tonnage_per_hour',
            'working_time',
          ],
        },
      ]
    },
    canExport() {
      return (
        (this.selectedTimeframe &&
          this.hasAtLeastOneExport &&
          !this.$apollo.queries.assetDimensions.loading &&
          this.assetDimensions.length > 0) ||
        false
      )
    },
    hasAtLeastOneExport() {
      return this.sectionsDisabled.length < this.sectionsMapped.length
    },
    locale() {
      return get(this.uiSettings, 'dates', 'DE_DE').toLowerCase().replace('_', '-')
    },
    selectedTimeframeShown() {
      if (!this.selectedTimeframe) {
        return ''
      }
      return `${this.selectedTimeframe.start
        .setLocale(this.locale)
        .toLocaleString(Object.assign(DateTime.DATETIME_SHORT))} - ${this.selectedTimeframe.end
        .setLocale(this.locale)
        .toLocaleString(Object.assign(DateTime.DATETIME_SHORT))}`
    },
    previousTimeframe() {
      if (!this.selectedTimeframe) {
        return null
      }
      const duration = Interval.fromDateTimes(this.selectedTimeframe.start, this.selectedTimeframe.end).toDuration()
      return {
        start: this.selectedTimeframe.start.minus(duration),
        end: this.selectedTimeframe.end.minus(duration),
        granularity: this.selectedTimeframe.granularity,
      }
    },
    selectedTimeframe() {
      if (get(this.selectedTimeFormat, 'id') === 'year' && this.selectedYear) {
        return {
          start: DateTime.local(this.selectedYear).startOf('year'),
          end: DateTime.local(this.selectedYear).endOf('year'),
          granularity: 'P1D',
        }
      }
      if (get(this.selectedTimeFormat, 'id') === 'month' && this.selectedMonth) {
        return {
          start: this.selectedMonth.dt.startOf('month'),
          end: this.selectedMonth.dt.endOf('month'),
          granularity: 'P1D',
        }
      }
      if (get(this.selectedTimeFormat, 'id') === 'week' && this.selectedWeek) {
        return {
          start: this.selectedWeek.dt.startOf('week'),
          end: this.selectedWeek.dt.endOf('week'),
          granularity: 'P1D',
        }
      }
      if (get(this.selectedTimeFormat, 'id') === 'day' && this.selectedDay) {
        return {
          start: this.selectedDay.dt.startOf('day'),
          end: this.selectedDay.dt.endOf('day'),
          granularity: 'PT1H',
        }
      }
      return null
    },
    monthsAvailable() {
      if (!this.selectedYear) {
        return []
      }
      const language = this.uiSettings.language.toLowerCase()
      const months = []
      for (let i = 1; i <= 12; i++) {
        const dt = DateTime.local(this.selectedYear).set({ month: i })
        months.push({
          id: i,
          dt,
          label: `${dt.setLocale(language).monthLong}`,
        })
      }
      return months
    },
    weeksAvailable() {
      if (!this.selectedYear) {
        return []
      }

      const language = this.uiSettings.language.toLowerCase()

      const calendarWeekAbbreviation = this.$t('reporting.stepper.stepOne.types.calendarWeek')

      const c = DateTime.local().set({ year: this.selectedYear }).weeksInWeekYear
      const weeks = []
      for (let i = 1; i <= c; i++) {
        const dt = DateTime.local().set({ year: this.selectedYear }).set({ weekNumber: i })
        weeks.push({
          id: i,
          dt,
          label: `${calendarWeekAbbreviation}-${i} - ${dt.setLocale(language).monthShort}`,
        })
      }
      return weeks
    },
    daysAvailable() {
      if (!this.selectedMonth) {
        return []
      }
      const c = this.selectedMonth.dt.daysInMonth
      const days = []
      for (let i = 1; i <= c; i++) {
        const dt = DateTime.local(this.selectedYear, this.selectedMonth.id, i)
        days.push({
          id: i,
          dt,
          label: `${i}`,
        })
      }
      return days
    },
    yearsAvailable() {
      const yearNow = DateTime.local().year
      const c = yearNow - 2017
      const years = []
      for (let i = 0; i < c; i++) {
        const p = DateTime.local().minus({ years: i }).year
        years.push(p)
      }
      return years
    },
    title() {
      return this.$t('navigation.cockpit.settings')
    },
    organizations() {
      return get(this.profile, 'organizations', [])
    },
    showYearSelection() {
      return (
        get(this.selectedTimeFormat, 'id') === 'year' ||
        get(this.selectedTimeFormat, 'id') === 'month' ||
        get(this.selectedTimeFormat, 'id') === 'week' ||
        get(this.selectedTimeFormat, 'id') === 'day'
      )
    },
    showMonthSelection() {
      return get(this.selectedTimeFormat, 'id') === 'month' || get(this.selectedTimeFormat, 'id') === 'day'
    },
    showWeekSelection() {
      return get(this.selectedTimeFormat, 'id') === 'week'
    },
    showDaySelection() {
      return get(this.selectedTimeFormat, 'id') === 'day'
    },
  },
  watch: {
    weeksAvailable: {
      handler(newWeeks) {
        this.updateSelectedValueWithNewOptions(newWeeks, 'selectedWeek')
      },
    },
    timeFormatsAvailable: {
      handler(newTimeFormats) {
        this.updateSelectedValueWithNewOptions(newTimeFormats, 'selectedTimeFormat')
      },
    },
    monthsAvailable: {
      handler(newMonths) {
        this.updateSelectedValueWithNewOptions(newMonths, 'selectedMonth')
      },
    },
    selectedYear: {
      handler() {
        this.selectedMonth = null
        this.selectedWeek = null
      },
    },
    selectedMonth: {
      handler() {
        this.selectedDay = null
      },
    },
  },
  methods: {
    updateSelectedValueWithNewOptions(newOptions, key) {
      const value = this[key]

      if (value) {
        this[key] = newOptions.find(({ id }) => id === value.id)
      }
    },
    toggleSection(label) {
      if (this.sectionsDisabled.includes(label)) {
        this.sectionsDisabled = this.sectionsDisabled.filter(f => f !== label)
      } else {
        this.sectionsDisabled.push(label)
      }
    },
    async exportCSV() {
      this.isExporting = true
      this.errors = []
      try {
        const assets = this.assetsSelected.map(asset => asset.id)
        const data = {}
        const promises = this.sectionsMapped.reduce((acc, section) => {
          if (section.enabled) {
            data[section.label] = {}
            section.dimensions.forEach(adName => {
              data[section.label][adName] = {}
              acc.push(
                limit(async () => {
                  data[section.label][adName]['current'] = await this.getAssetDimensionData(assets, this.selectedTimeframe, adName)
                }),
              )
              acc.push(
                limit(async () => {
                  data[section.label][adName]['prev'] = await this.getAssetDimensionData(assets, this.previousTimeframe, adName)
                }),
              )
            })
          }
          return acc
        }, [])
        this.queuedQueries = promises.length
        this.finishedQueries = 0
        this.startedQueries = 0
        await Promise.all(promises)
        this.generateCSVZipFolder(data)
      } catch (err) {
        this.errors = [err]
      } finally {
        this.isExporting = false
      }
    },
    async getAssetDimensionData(assets, timeframe, assetDimensionName) {
      this.startedQueries++
      const {
        data: { assetDimensionData },
      } = await this.$apollo.query({
        query: CSV_DATA_QUERY,
        variables: {
          where: {
            assetDimension: {
              name: assetDimensionName,
            },
            timeframe,
            assets: {
              id_in: assets,
            },
          },
        },
      })

      this.finishedQueries++
      return assetDimensionData
    },
    exportToCSVBlob(data, section) {
      let rows = []
      if (section.label === 'summary') {
        const columnCount = section.dimensions.length + 1
        const header = [
          this.$t('reporting.sections.' + section.label),
          `${this.selectedTimeframe.start
            .setLocale(this.locale)
            .toLocaleString(Object.assign(DateTime.DATETIME_SHORT))} - ${this.selectedTimeframe.end
            .setLocale(this.locale)
            .toLocaleString(Object.assign(DateTime.DATETIME_SHORT))}`,
        ]
        for (let i = 0; i <= columnCount - 2; i++) {
          header.push('')
        }
        const subHeaderRows = section.dimensions.map(dimension => {
          const found = this.assetDimensions.find(f => f.name === dimension)
          const unitUISi = get(found, 'physicalUnitSI', null)
          const unitUIMetric = get(found, 'physicalUnitUIMetric', null)
          const unitUIImperial = get(found, 'physicalUnitUIImperial', null)
          const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
          const displayedUnit = unitUI || unitUISi
          const translatedName = this.kpiNameFinder(found?.name)
          if (!found) {
            throw new Error(`missing access to AssetDimension ${dimension}`)
          }
          return `${translatedName} [${displayedUnit}]`
        })
        const subheader = [this.$tc('asset', 1), ...subHeaderRows]
        for (let i = 0; i <= columnCount - 2; i++) {
          subheader.push('')
        }
        const assetRows = this.assets.map(asset => {
          return [
            asset.name,
            ...section.dimensions.map(dimension => {
              const found = this.assetDimensions.find(f => f.name === dimension)
              const unitSI = get(found, 'physicalUnitSI', null)
              const unitUIMetric = get(found, 'physicalUnitUIMetric', null)
              const unitUIImperial = get(found, 'physicalUnitUIImperial', null)
              const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
              if (!found) {
                throw new Error(`missing access to AssetDimension ${dimension}`)
              }
              const value = data[section.label][dimension]['current']['aggrByAsset'].find(aggr => aggr.assetId === asset.id)
              const currentVal = value?.floatValue ?? null
              return currentVal !== null ? (unitSI && unitUI ? convert(currentVal).from(unitSI).to(unitUI) : currentVal) : '-'
            }),
          ]
        })

        const totalRows = [
          this.$tc('total', 1),
          ...section.dimensions.map(dimension => {
            const found = this.assetDimensions.find(f => f.name === dimension)
            const unitSI = get(found, 'physicalUnitSI', null)
            const unitUIMetric = get(found, 'physicalUnitUIMetric', null)
            const unitUIImperial = get(found, 'physicalUnitUIImperial', null)
            const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
            if (!found) {
              throw new Error(`missing access to AssetDimension ${dimension}`)
            }
            const currentVal = data[section.label][dimension]['current']['total']
            return currentVal !== null ? (unitSI && unitUI ? convert(currentVal).from(unitSI).to(unitUI) : currentVal) : '-'
          }),
        ]
        rows = [header, subheader, ...assetRows, totalRows]
      } else {
        const columnCount = 5
        const header = [this.$t('reporting.sections.' + section.label)]
        for (let i = 0; i <= columnCount - 2; i++) {
          header.push('')
        }
        const subheader = [
          'KPI',
          this.$tc('unit', 1),
          `${this.selectedTimeframe.start
            .setLocale(this.locale)
            .toLocaleString(Object.assign(DateTime.DATETIME_SHORT))} - ${this.selectedTimeframe.end
            .setLocale(this.locale)
            .toLocaleString(Object.assign(DateTime.DATETIME_SHORT))}`,
          `${this.previousTimeframe.start
            .setLocale(this.locale)
            .toLocaleString(Object.assign(DateTime.DATETIME_SHORT))} - ${this.previousTimeframe.end
            .setLocale(this.locale)
            .toLocaleString(Object.assign(DateTime.DATETIME_SHORT))}`,
          this.$t('reporting.table.deviation'),
        ]
        for (let i = 0; i <= columnCount - 2; i++) {
          subheader.push('')
        }
        const dataRows = section.dimensions.map(dimension => {
          const found = this.assetDimensions.find(f => f.name === dimension)
          if (!found) {
            throw new Error(`missing access to AssetDimension ${dimension}`)
          }
          const unitSI = get(found, 'physicalUnitSI', null)
          const unitUIMetric = get(found, 'physicalUnitUIMetric', null)
          const unitUIImperial = get(found, 'physicalUnitUIImperial', null)
          let unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
          const translatedName = this.kpiNameFinder(found?.name)
          const currentVal = data[section.label][dimension]['current']['total']
          const prevVal = data[section.label][dimension]['prev']['total']

          const current = unitSI && unitUI ? convert(currentVal).from(unitSI).to(unitUI) : currentVal
          const prev = unitSI && unitUI ? convert(prevVal).from(unitSI).to(unitUI) : prevVal

          unitUI = unitUI || get(found, 'physicalUnitSI', '')

          const difference = data[section.label][dimension]['current']['total'] - data[section.label][dimension]['prev']['total']
          const deviationPercent = (difference * 100) / data[section.label][dimension]['prev']['total']
          const deviation = deviationPercent ? (deviationPercent !== Infinity ? `${round(deviationPercent, 2)} %` : 'Infinity') : 0
          return [translatedName, unitUI.replace('mt', 't'), current, prev, deviation]
        })
        rows = [header, subheader, ...dataRows]
      }
      return exportToBlob(rows)
    },
    generateCSVZipFolder(data) {
      const _this = this
      const zip = JSZip()
      const interval = this.selectedTimeframeShown.replace(/\s+/gi, '_').replace(/(:|,|\.|\/)+/gi, '-')
      this.sectionsMapped
        .filter(f => f.enabled)
        .forEach(section => {
          const blob = this.exportToCSVBlob(data, section)
          zip.file(`${this.$t('reporting.sections.' + section.label)}.csv`, blob)
        })
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `talpasolutions_${_this.$tc('types.report', 1)}_${interval}.zip`)
      })
    },
    errorMapped(error) {
      this.errors = error ? [this.$t(`reporting.error.downloadError`)] : []
    },
    pdfLoading(isLoading) {
      this.isPdfLoading = isLoading
    },
    kpiNameFinder(kpi) {
      const found = this.assetDimensions.find(f => f.name === kpi)
      const locale = this.locale.slice(0, 2).toUpperCase()
      return found?.nameTranslations ? getAssetDimensionNameByLocale(found?.nameTranslations, locale) : found?.name
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
    assets: {
      query: ASSETS_MINIMAL_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
      result({ data }) {
        this.assetStore.allAssets = data?.assets ?? []
      },
    },
    assetDimensions: {
      query: ASSETS_DIMENSIONS_QUERY,
      variables() {
        const names = this.assetDimensionNames
        return {
          where: {
            name_in: names,
          },
        }
      },
    },
  },
}
</script>
