<template>
  <PDFReportStyled>
    <ButtonRound v-if="canDownloadPDF" :disabled="!canExport" @click="download()"> Download PDF </ButtonRound>
    <div v-if="isLoading" class="loading">{{ $t('reporting.report.processing') }} ...</div>
  </PDFReportStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ButtonRound as ButtonRoundBase } from '@styles/buttons'
import { getPDFReport } from '@/components/Service/ReportingService'
import { DateTime } from 'luxon'
import localesMixin from '@/mixins/locales'
const PDFReportStyled = styled('div')``
const ButtonRound = styled(ButtonRoundBase)`
  background: ${({ theme }) => theme.colors.primary};
  width: 10rem;
  border-radius: 1.5rem;
  margin-right: 1rem;
`
export default {
  inject: ['uiSettings'],
  mixins: [localesMixin],
  props: {
    reportingPDFRead: {
      type: Object,
      required: false,
    },
    assetsSelected: {
      type: Array,
      required: true,
    },
    sections: {
      type: Array,
      required: true,
    },
    selectedTimeframe: {
      type: Object,
    },
    canExport: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    PDFReportStyled,
    ButtonRound,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    canDownloadPDF() {
      return this.reportingPDFRead && !this.isLoading
    },
    assetsMapped() {
      return this.assetsSelected.map(asset => {
        return {
          id: asset.id,
          type: asset.type.name,
          defaultName: asset.name,
          site: asset.site ? asset.site.name : null,
          model: asset.model.name,
        }
      })
    },
    assetDimensionsSelected() {
      let mapObj = {
        tonnage: 'sum_tonnage',
        no_passes: 'sum_passes',
        average_loading_duration: 'avg_loading_duration',
        distance: 'sum_distance',
        fuel_consumption: 'sum_fuel',
      }
      const promises = this.sections.reduce((acc, section) => {
        if (section.enabled) {
          section.dimensions.forEach(adName => {
            if (mapObj[adName]) {
              acc.push(mapObj[adName])
            } else {
              acc.push(adName)
            }
          })
        }
        return acc
      }, [])
      return [...new Set(promises)]
    },
  },
  methods: {
    download() {
      this.isLoading = true
      this.$emit('error', '')
      this.$emit('pdfLoading', this.isLoading)
      const body = {
        timeFrame: {
          startDate: this.selectedTimeframe.start.toUTC().toISO(),
          endDate: this.selectedTimeframe.end.toUTC().toISO(),
          timeZone: DateTime.local().zoneName,
          granularity: this.selectedTimeframe.granularity,
          additionalProp1: {},
        },
        unit: this.selectedUIUnitSystem.toLowerCase(),
        assets: this.assetsMapped,
        KPIS: this.assetDimensionsSelected,
      }
      const language = this.uiSettings.language.toLowerCase()
      const token = this.$keycloak.token
      getPDFReport(language, token, body)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'Reports.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(error => {
          this.$emit('error', error)
        })
        .finally(() => {
          this.isLoading = false
          this.$emit('pdfLoading', this.isLoading)
        })
    },
  },
}
</script>
