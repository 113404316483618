var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Subheader', {
    attrs: {
      "hasScrolled": _vm.hasScrolled
    }
  }), _c('ReportingMainStyled', [_c('header', [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('main', [_c('div', {
    staticClass: "timeformat"
  }, [_c('div', {
    staticClass: "label"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t('reporting.report.timeFormat')) + " ")])]), _c('Multiselect', {
    staticClass: "select",
    attrs: {
      "trackBy": "id",
      "label": "label",
      "options": _vm.timeFormatsAvailable,
      "multiple": false,
      "show-labels": false,
      "placeholder": _vm.$t('selects.general')
    },
    model: {
      value: _vm.selectedTimeFormat,
      callback: function callback($$v) {
        _vm.selectedTimeFormat = $$v;
      },
      expression: "selectedTimeFormat"
    }
  })], 1), _c('div', {
    staticClass: "year",
    class: {
      faded: !_vm.showYearSelection
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t('reporting.stepper.stepOne.types.yearly')) + " ")])]), _c('Multiselect', {
    staticClass: "select",
    attrs: {
      "options": _vm.yearsAvailable,
      "multiple": false,
      "show-labels": false,
      "placeholder": _vm.$t('selects.general')
    },
    model: {
      value: _vm.selectedYear,
      callback: function callback($$v) {
        _vm.selectedYear = $$v;
      },
      expression: "selectedYear"
    }
  })], 1), _c('div', {
    staticClass: "month",
    class: {
      faded: !_vm.showMonthSelection
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t('reporting.stepper.stepOne.types.monthly')) + " ")])]), _c('Multiselect', {
    staticClass: "select",
    attrs: {
      "trackBy": "id",
      "label": "label",
      "disabled": !this.selectedYear,
      "options": _vm.monthsAvailable,
      "multiple": false,
      "show-labels": false,
      "placeholder": _vm.$t('selects.general')
    },
    model: {
      value: _vm.selectedMonth,
      callback: function callback($$v) {
        _vm.selectedMonth = $$v;
      },
      expression: "selectedMonth"
    }
  })], 1), _c('div', {
    staticClass: "week",
    class: {
      faded: !_vm.showWeekSelection
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t('reporting.stepper.stepOne.types.weekly')) + " ")])]), _c('Multiselect', {
    staticClass: "select",
    attrs: {
      "trackBy": "id",
      "label": "label",
      "disabled": !this.selectedYear,
      "options": _vm.weeksAvailable,
      "multiple": false,
      "show-labels": false,
      "placeholder": _vm.$t('selects.general')
    },
    model: {
      value: _vm.selectedWeek,
      callback: function callback($$v) {
        _vm.selectedWeek = $$v;
      },
      expression: "selectedWeek"
    }
  })], 1), _c('div', {
    staticClass: "day",
    class: {
      faded: !_vm.showDaySelection
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t('reporting.stepper.stepOne.types.daily')) + " ")])]), _c('Multiselect', {
    staticClass: "select",
    attrs: {
      "trackBy": "id",
      "label": "label",
      "disabled": !this.selectedMonth,
      "options": _vm.daysAvailable,
      "multiple": false,
      "show-labels": false,
      "placeholder": _vm.$t('selects.general')
    },
    model: {
      value: _vm.selectedDay,
      callback: function callback($$v) {
        _vm.selectedDay = $$v;
      },
      expression: "selectedDay"
    }
  })], 1), _c('div', {
    staticClass: "selected-timeframe",
    class: {
      faded: !_vm.selectedTimeframe
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t('reporting.stepper.stepOne.subtitle')) + " ")])]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.selectedTimeframeShown) + " ")])]), _c('div', {
    staticClass: "section"
  }, _vm._l(_vm.sectionsMapped, function (section) {
    return _c('div', {
      key: section.label,
      staticClass: "export"
    }, [_c('ToggleButton', {
      on: {
        "click": function click($event) {
          return _vm.toggleSection(section.label);
        }
      }
    }, [_c('OnOffToggle', {
      staticClass: "toggle",
      attrs: {
        "value": section.enabled
      }
    }), _vm._v(" " + _vm._s(_vm.$t('reporting.sections.' + section.label)) + " ")], 1), _c('div', {
      staticClass: "details"
    }, _vm._l(section.dimensions, function (dimension, i) {
      return _c('Dimension', {
        key: i
      }, [_vm._v(" " + _vm._s(_vm.kpiNameFinder(dimension)) + " ")]);
    }), 1)], 1);
  }), 0), _c('div', {
    staticClass: "download"
  }, [_vm.canDownloadCSV ? _c('ButtonRound', {
    attrs: {
      "disabled": !_vm.canExport
    },
    on: {
      "click": _vm.exportCSV
    }
  }, [_vm._v(" Download CSV ")]) : _vm._e(), !_vm.isExporting ? _c('PDFReport', {
    attrs: {
      "reportingPDFRead": _vm.reportingPDFRead,
      "assetsSelected": _vm.assetsSelected,
      "selectedTimeframe": _vm.selectedTimeframe,
      "canExport": _vm.canExport,
      "sections": _vm.sectionsMapped
    },
    on: {
      "error": function error($event) {
        return _vm.errorMapped($event);
      },
      "pdfLoading": _vm.pdfLoading
    }
  }) : _c('div', {
    staticClass: "loading"
  }, [_vm._v(_vm._s(_vm.$t('reporting.report.processing')) + " " + _vm._s(_vm.loadingPercent) + " %")])], 1)]), _vm.errors.length > 0 ? _c('div', {
    staticClass: "errors"
  }, _vm._l(_vm.errors, function (error, i) {
    return _c('div', {
      key: i,
      staticClass: "error"
    }, [_vm._v(" " + _vm._s(error.toString()) + " ")]);
  }), 0) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }