var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PDFReportStyled', [_vm.canDownloadPDF ? _c('ButtonRound', {
    attrs: {
      "disabled": !_vm.canExport
    },
    on: {
      "click": function click($event) {
        return _vm.download();
      }
    }
  }, [_vm._v(" Download PDF ")]) : _vm._e(), _vm.isLoading ? _c('div', {
    staticClass: "loading"
  }, [_vm._v(_vm._s(_vm.$t('reporting.report.processing')) + " ...")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }